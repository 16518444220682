import { request } from '@/utils/Tool'

export function checkVerifiCodeE(email, emailcode) {
    const data = {
        email: email,
        emailcode: emailcode
    }
    return new Promise(function(resolve, reject) {
        request('CSKY_checkEmailVerifiCode', data).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export function getCodeE(data) {
    const param = {
        sysname: import.meta.env.VITE_MESSAGE,
        ...data
    }
    return new Promise(function(resolve, reject) {
        request('CSKY_getEmailCode', param).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export function blindOrModifyPhoneAndEmail(data) {
    return new Promise(function(resolve, reject) {
        request('CSKY_blindOrModifyPhoneAndEmail', data).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export function checkPhoneCodeAsPhone(phone, phonecode) {
    var data = {
        'phone': phone,
        'phonecode': phonecode
    }
    return new Promise(function(resolve, reject) {
        request('czcjsy_checkPhoneCodeAsPhone', data).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}

export function sendShortMessage(data) {
    const param = {
        sysname: import.meta.env.VITE_MESSAGE,
        ...data
    }
    return new Promise(function(resolve, reject) {
        request('CSKY_sendShortMessage', param).then((result) => {
            if (result) {
                resolve(result)
            } else {
                reject(null)
            }
        })
    })
}
